
import React, {useState} from "react"
import Layout from "../../components/layout"

import WebappForm from "../../components/webapps/form"

const ENTITYID="nnfranchise"

const searchFields = [
	{"label":"Franchisor", "dbfield": "onsorganization_name", "type": "text", "filtertype": "text"},
	{"label":"Name", "dbfield": "nnfranchise_name", "type": "text", "filtertype": "text"},
	{"label":"Accepting Applicants", "dbfield": "nnfranchise_active", "type": "checkbox", "filtertype": "checkbox"},
	{"label":"Logo", "dbfield": "nnfranchise_logo", "type": "image", "filtertype": "image"},
	{"label":"Industry", "dbfield": "nnfranchiseindustry_name", "type": "text", "filtertype": "text"},
	{"label":"Summary", "dbfield": "nnfranchise_summary", "type": "text", "filtertype": "text"}
];


const formFields = [
	{"label":"Franchisor", "field": "onsorganization_name", "value": "", "input": "picker", "mode": "readonly"},
	{"label":"Name", "field": "nnfranchise_name", "value": "", "input": "textbox", "mode": "readonly"},
	{"label":"Webpage", "field": "nnfranchise_actualurl", "value": "", "input": "url", "mode": "readonly", "urlprefix":"https://negosyonow.com/franchise/"},
	{"label":"Logo", "field": "nnfranchise_logo", "value": "", "input": "image", "mode": "readonly"},
];

const subformFields = [
	{
		"subformid": "nnfranchisenotification",
		"label": "Notification List",
		"table": "nnfranchisenotification",
		"sort": "nnfranchisenotification_value",
		"mobilerowtitlefieldidx":[0],
		"minrow": 1,
		"maxrow": 5,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Email Address",
				"field": "nnfranchisenotification_value",
				"subtotalid": "",
				"value": "",
				"input": "email",
				"mode": "required"
			},
			{
				"label": "Active",
				"field": "nnfranchisenotification_active",
				"subtotalid": "",
				"value": "",
				"input": "checkbox",
				"mode": "normal"
			},
			{
				"label": "Custodian/Notes",
				"field": "nnfranchisenotification_notes",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "normal"
			}
		],
		"footerlist": [
		]
	}
];




const FranchiseNotificationPage = ({location}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");

	return <Layout
			fullPath={location.pathname}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			privatePage={true} usegatedcontent={true}
		>
			<WebappForm
				pagetitle={pagetitle}
				allowadd={false}
				allowdelete={false}
				entity={ENTITYID}
				mobilerowtitlefield={["nnfranchise_name"]}
				searchFields={searchFields}
				editFields={[formFields]}
				editSubFormFields={subformFields}
				token={token} />
		</Layout>

}


export default FranchiseNotificationPage;
